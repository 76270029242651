body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-page {
  padding: 20px;
}
.header {
  position: relative;
  background: #252C5F;
}
.header>ul {
  padding-top: .625em;
}
.header .logo {
  display: inline-block;
  margin: .625em 1.25em;
  width: 3em;
  position: relative;
  z-index: 5;
  transform: scale(1.8) translate(.5em, .4em);
}

.header>h3 {
  display: inline-block;
  position: relative;
  left: 1.5em;
  top: .2em;
  color: white;
}